<template>
  <div class="flex h-full items-center">
    <a href="/" class="hidden lg:block">
      <img src="/img/logo-img.png" class="h-10" alt="Logo" />
    </a>
    <a href="/" class="block lg:hidden">
      <img src="/img/logo-img-only.png" class="h-10" alt="Logo" />
    </a>
  </div>
</template>
